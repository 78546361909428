import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/formatDate';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useTheme } from '../context/ThemeContext';

function BlogPost({ posts }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { isDark } = useTheme();
  
  const post = posts.find(p => p.slug === slug);
  
  useEffect(() => {
    if (!post) {
      navigate('/blog', { replace: true });
    }
  }, [post, navigate]);
  
  if (!post) {
    return null;
  }

  const currentIndex = posts.findIndex(p => p.slug === slug);
  const nextPost = currentIndex > 0 ? posts[currentIndex - 1] : null;
  const prevPost = currentIndex < posts.length - 1 ? posts[currentIndex + 1] : null;
  
  // Function to get language accent styles - simplified for minimalism
  const getLanguageAccent = (language) => {
    const accents = {
      javascript: 'accent-amber-400',
      typescript: 'accent-blue-500',
      python: 'accent-green-500',
      jsx: 'accent-cyan-400',
      html: 'accent-orange-500',
      css: 'accent-purple-500',
      go: 'accent-cyan-400',
      bash: 'accent-gray-500',
      shell: 'accent-gray-500',
      json: 'accent-amber-400',
      rust: 'accent-orange-600',
      sql: 'accent-blue-500',
      c: 'accent-blue-500',
      cpp: 'accent-blue-600',
      java: 'accent-red-500',
      ruby: 'accent-red-600',
      php: 'accent-indigo-500',
    };
    
    return accents[language] || 'accent-gray-400';
  };
  
  // Function to get language display name
  const getLanguageLabel = (language) => {
    const labels = {
      js: 'JavaScript',
      javascript: 'JavaScript',
      ts: 'TypeScript',
      typescript: 'TypeScript',
      py: 'Python',
      python: 'Python',
      jsx: 'React JSX',
      html: 'HTML',
      css: 'CSS',
      go: 'Go',
      bash: 'Bash',
      shell: 'Shell',
      json: 'JSON',
      rust: 'Rust',
      sql: 'SQL',
      c: 'C',
      cpp: 'C++',
      java: 'Java',
      rb: 'Ruby',
      ruby: 'Ruby',
      php: 'PHP',
    };
    
    return labels[language] || language?.toUpperCase() || 'Code';
  };
  
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="pt-28 pb-16 max-w-3xl mx-auto px-4">
        <motion.div 
          className="mb-6 flex items-center gap-4"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Link 
            to="/blog" 
            className="flex items-center gap-2 px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
          >
            <span>←</span>
            <span>All Posts</span>
          </Link>
        </motion.div>

        <motion.article
          className="bg-white dark:bg-gray-800 rounded-xl border-2 border-gray-200 dark:border-gray-700 shadow-sm overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <header className="p-6 md:p-8 border-b border-gray-200 dark:border-gray-700">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white mb-2">
              {post.title}
            </h1>
            {post.date && (
              <p className="text-gray-500 dark:text-gray-400 text-sm">
                Published on {formatDate(post.date)}
              </p>
            )}
            {post.description && (
              <p className="mt-4 text-lg text-gray-600 dark:text-gray-300 italic">
                {post.description}
              </p>
            )}
          </header>
          
          <div className="p-6 md:p-8">
            <div className="prose dark:prose-invert prose-headings:text-gray-900 dark:prose-headings:text-white prose-a:text-blue-600 dark:prose-a:text-blue-400 max-w-none">
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                components={{
                  code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '');
                    const language = match ? match[1] : '';
                    const accent = getLanguageAccent(language);
                    const displayLang = getLanguageLabel(language);
                    
                    return !inline && match ? (
                      <div className="group relative my-6">
                        {/* Minimalist language label */}
                        <div className={`absolute top-0 right-0 px-2 py-1 text-xs font-medium rounded-bl-md z-10 
                          ${isDark ? 'bg-gray-800' : 'bg-gray-50'} ${accent}`}>
                          {displayLang}
                        </div>
                        
                        {/* Copy button - more minimal */}
                        <button 
                          onClick={() => {
                            navigator.clipboard.writeText(String(children).replace(/\n$/, ''));
                          }}
                          className="absolute top-0 right-16 p-2 rounded-bl-md text-gray-500 dark:text-gray-400 
                            opacity-0 group-hover:opacity-100 transition-opacity"
                          aria-label="Copy code"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" 
                            fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" 
                            strokeLinejoin="round" className="feather feather-clipboard">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                            <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                          </svg>
                        </button>
                        
                        {/* Minimalist code container */}
                        <div className={`rounded-md overflow-hidden ${isDark ? 'bg-gray-900' : 'bg-gray-50'} 
                          border border-gray-200 dark:border-gray-700`}>
                          <SyntaxHighlighter
                            language={language}
                            style={isDark ? tomorrow : oneLight}
                            customStyle={{
                              background: 'transparent',
                              padding: '1rem',
                              borderRadius: '0.375rem',
                              fontSize: '0.9em',
                              margin: 0,
                            }}
                            showLineNumbers={true}
                            lineNumberStyle={{
                              minWidth: '2.5em',
                              paddingRight: '1em',
                              color: isDark ? '#606060' : '#A0A0A0',
                              textAlign: 'right',
                              userSelect: 'none',
                            }}
                            {...props}
                          >
                            {String(children).replace(/\n$/, '')}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    ) : (
                      <code className="px-1.5 py-0.5 rounded bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 font-mono text-sm">
                        {children}
                      </code>
                    );
                  },
                  a({node, children, href, ...props}) {
                    return (
                      <a 
                        href={href} 
                        target={href.startsWith('http') ? "_blank" : undefined} 
                        rel={href.startsWith('http') ? "noopener noreferrer" : undefined}
                        className="text-blue-600 dark:text-blue-400 hover:underline"
                        {...props}
                      >
                        {children}
                      </a>
                    );
                  },
                  img({node, src, alt, ...props}) {
                    return (
                      <img 
                        src={src} 
                        alt={alt} 
                        className="rounded-lg border border-gray-200 dark:border-gray-700"
                        {...props} 
                      />
                    );
                  },
                  blockquote({node, children, ...props}) {
                    return (
                      <blockquote 
                        className="border-l-4 border-blue-500 dark:border-blue-400 pl-4 py-1 italic text-gray-600 dark:text-gray-300"
                        {...props}
                      >
                        {children}
                      </blockquote>
                    );
                  }
                }}
              >
                {post.content}
              </ReactMarkdown>
            </div>
          </div>
        </motion.article>
        
        {/* Post Navigation */}
        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
          {prevPost && (
            <Link 
              to={`/blog/${prevPost.slug}`}
              className="p-4 rounded-xl border-2 border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
            >
              <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">← Previous Post</p>
              <h3 className="text-gray-900 dark:text-white font-medium">{prevPost.title}</h3>
            </Link>
          )}
          
          {nextPost && (
            <Link 
              to={`/blog/${nextPost.slug}`}
              className={`p-4 rounded-xl border-2 border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors ${!prevPost ? 'md:col-start-2' : ''}`}
            >
              <p className="text-sm text-gray-500 dark:text-gray-400 mb-2 text-right">Next Post →</p>
              <h3 className="text-gray-900 dark:text-white font-medium text-right">{nextPost.title}</h3>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
