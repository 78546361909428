import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/formatDate';
import { motion } from 'framer-motion';

function BrainDumpList({ posts }) {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="pt-28 pb-16 max-w-3xl mx-auto px-4">
        <motion.div 
          className="flex items-center gap-4 mb-10"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Link 
            to="/" 
            className="flex items-center gap-2 px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
          >
            <span>←</span>
            <span>Home</span>
          </Link>
          <h1 className="text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 dark:from-blue-400 dark:to-purple-400 bg-clip-text text-transparent">
            Brain Dump
          </h1>
        </motion.div>
        
        <div className="space-y-6">
          {posts.map((post, index) => (
            <motion.div
              key={post.slug}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
            >
              <Link
                to={`/blog/${post.slug}`}
                className="block bg-white dark:bg-gray-800 p-6 rounded-xl border-2 border-gray-200 dark:border-gray-700 shadow-sm hover:shadow-md dark:hover:border-gray-600 transition-all duration-200"
              >
                <article>
                  <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                    {post.title}
                  </h2>
                  {post.date && (
                    <p className="text-gray-500 dark:text-gray-400 mb-3 text-sm border-l-4 border-gray-200 dark:border-gray-700 pl-3">
                      {formatDate(post.date)}
                    </p>
                  )}
                  {post.description && (
                    <p className="text-gray-600 dark:text-gray-300 mb-3 font-medium">
                      {post.description}
                    </p>
                  )}
                  <p className="text-gray-600 dark:text-gray-300 line-clamp-3">
                    {post.excerpt}
                  </p>
                  <div className="mt-4 flex items-center">
                    <span className="text-sm text-blue-600 dark:text-blue-400 font-medium">
                      Read more →
                    </span>
                  </div>
                </article>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BrainDumpList;
