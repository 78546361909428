import React from 'react';
import { Link } from 'react-router-dom';
import { FaGithub, FaLinkedin, FaExternalLinkAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import profilePic from '../assets/profile.png';
import { projects } from '../utils/projects';
import { blogPosts } from '../utils/blogPosts';

function Home() {
  const featuredProjects = projects.filter(project => project.status === 'Live' || project.status === 'In Development' || project.status === 'Done').slice(0, 3);
  const latestPosts = blogPosts.slice(0, 3);

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      {/* Hero Section - Adjusted padding to account for floating navbar */}
      <section className="min-h-[80vh] flex items-center justify-center relative overflow-hidden pt-24">
        <div className="absolute inset-0 bg-gradient-to-b from-white to-white dark:from-gray-900/30 dark:to-gray-900" />
        
        <div className="max-w-5xl mx-auto px-4 relative">
          <div className="flex flex-col md:flex-row items-center gap-10 md:gap-16 py-10 md:py-20">
            <motion.div 
              className="relative group"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="w-56 h-56 md:w-80 md:h-80">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-xl md:rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <img
                  src={profilePic}
                  alt="Joneskim"
                  className="rounded-xl md:rounded-2xl w-full h-full object-cover border-2 border-gray-200 dark:border-gray-700 shadow-xl relative z-10 group-hover:scale-[1.02] transition-transform duration-500 filter grayscale hover:grayscale-0"
                  />
                <div className="absolute inset-0 rounded-xl md:rounded-2xl bg-gradient-to-r from-blue-500/10 to-purple-500/10 z-20 pointer-events-none group-hover:from-blue-400/20 group-hover:to-purple-400/20 transition-all duration-500" />
              </div>
            </motion.div>
            
            <motion.div 
              className="flex-1 text-center md:text-left"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="space-y-5">
                <h1 className="text-4xl md:text-6xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 dark:from-blue-400 dark:to-purple-400 bg-clip-text text-transparent">
                  Karibu!!!
                </h1>

                <p className="text-lg text-gray-700 dark:text-gray-300 max-w-2xl leading-relaxed">
                  Hi! I'm Joneskim Kimo, and I study Physics and Computer Science at the University of Chicago.
                  I'm passionate about neutrinos (the tau neutrino is my favorite, by the way), distributed systems, applied AI, EdTech, black holes, playing the piano, biking, and supporting Arsenal (next season is definitely our season), among many other things.
                </p>
                
                <p className="text-lg text-gray-700 dark:text-gray-300 max-w-2xl leading-relaxed">
                  On this page, I'll be sharing some of my projects, random thoughts about tech, physics, and whatever else comes to mind. Welcome, and enjoy!
                </p>

                <div className="flex items-center justify-center md:justify-start space-x-6 pt-4">
                  <motion.a
                    href="https://github.com/joneskim"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-3 px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors group"
                    aria-label="GitHub Profile"
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.97 }}
                  >
                    <FaGithub size={24} />
                    <span className="font-medium">GitHub</span>
                  </motion.a>
                  <motion.a
                    href="https://linkedin.com/in/joneskim-kimo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-3 px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors group"
                    aria-label="LinkedIn Profile"
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.97 }}
                  >
                    <FaLinkedin size={24} />
                    <span className="font-medium">LinkedIn</span>
                  </motion.a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Featured Projects Section */}
      <section className="py-16 bg-white dark:bg-gray-900">
        <div className="max-w-3xl mx-auto px-4">
          <motion.div 
            className="flex justify-between items-center mb-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 dark:from-blue-400 dark:to-purple-400 bg-clip-text text-transparent">
              Featured Projects
            </h2>
            <Link
              to="/projects"
              className="flex items-center gap-1 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
            >
              View All <span className="ml-1">→</span>
            </Link>
          </motion.div>
          <div className="grid gap-6">
            {featuredProjects.map((project, index) => (
              <motion.div
                key={project.slug}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Link
                  to={`/projects/${project.slug}`}
                  className="block bg-white dark:bg-gray-800 p-6 rounded-xl border-2 border-gray-200 dark:border-gray-700 shadow-sm hover:shadow-md dark:hover:border-gray-600 transition-all duration-200"
                >
                  <div className="flex flex-wrap justify-between items-start gap-4 mb-4">
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                      {project.title}
                    </h3>
                    <span className={`px-3 py-1 rounded-lg text-sm border ${
                      project.status === 'Live'
                        ? 'bg-green-500/10 text-green-600 dark:text-green-400 border-green-200 dark:border-green-800'
                        : 'bg-yellow-500/10 text-yellow-600 dark:text-yellow-400 border-yellow-200 dark:border-yellow-800'
                    }`}>
                      {project.status}
                    </span>
                  </div>
                  <p className="text-gray-600 dark:text-gray-300 mb-6">{project.description}</p>
                  <div className="flex flex-wrap items-center justify-between gap-4">
                    <div className="flex flex-wrap gap-2">
                      {project.technologies.map((tech) => (
                        <span
                          key={tech}
                          className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 px-3 py-1 rounded-lg text-sm border border-gray-200 dark:border-gray-700"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                    <div className="flex items-center space-x-4">
                      {project.links?.github && (
                        <a
                          href={project.links.github}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-blue-400 transition-colors p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                          aria-label="GitHub Repository"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <FaGithub size={20} />
                        </a>
                      )}
                      {project.links?.live && (
                        <a
                          href={project.links.live}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-blue-400 transition-colors p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                          aria-label="Live Demo"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <FaExternalLinkAlt size={18} />
                        </a>
                      )}
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Latest Blog Posts Section */}
      <section className="py-16 bg-white dark:bg-gray-900">
        <div className="max-w-3xl mx-auto px-4">
          <motion.div 
            className="flex justify-between items-center mb-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 dark:from-blue-400 dark:to-purple-400 bg-clip-text text-transparent">
              Latest Brain Dumps
            </h2>
            <Link
              to="/blog"
              className="flex items-center gap-1 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
            >
              View All <span className="ml-1">→</span>
            </Link>
          </motion.div>
          <div className="grid gap-6">
            {latestPosts.map((post, index) => (
              <motion.div
                key={post.slug}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Link
                  to={`/blog/${post.slug}`}
                  className="block bg-white dark:bg-gray-800 p-6 rounded-xl border-2 border-gray-200 dark:border-gray-700 shadow-sm hover:shadow-md dark:hover:border-gray-600 transition-all duration-200"
                >
                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                    {post.title}
                  </h3>
                  <p className="text-gray-500 dark:text-gray-400 mb-3 text-sm border-l-4 border-gray-200 dark:border-gray-700 pl-3">{post.date}</p>
                  <p className="text-gray-600 dark:text-gray-300">{post.excerpt}</p>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
