import React from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

function ProjectDetail({ projects }) {
  const { slug } = useParams();
  const project = projects.find((p) => p.slug === slug);

  if (!project) {
    return (
      <div className="min-h-screen bg-white dark:bg-gray-900">
        <div className="pt-28 pb-16 max-w-3xl mx-auto px-4">
          <motion.div 
            className="mb-6 flex items-center gap-4"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Link 
              to="/projects" 
              className="flex items-center gap-2 px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
            >
              <span>←</span>
              <span>All Projects</span>
            </Link>
          </motion.div>
          <div className="bg-white dark:bg-gray-800 p-8 rounded-xl border-2 border-gray-200 dark:border-gray-700 shadow-sm">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white mb-4">
              Project not found
            </h1>
            <p className="text-gray-600 dark:text-gray-300">
              The project you're looking for doesn't exist.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="pt-28 pb-16 max-w-3xl mx-auto px-4">
        <motion.div 
          className="mb-6 flex items-center gap-4"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Link 
            to="/projects" 
            className="flex items-center gap-2 px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
          >
            <span>←</span>
            <span>All Projects</span>
          </Link>
        </motion.div>

        <motion.article
          className="bg-white dark:bg-gray-800 rounded-xl border-2 border-gray-200 dark:border-gray-700 shadow-sm overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <header className="p-6 md:p-8 border-b border-gray-200 dark:border-gray-700">
            <div className="flex flex-wrap justify-between items-start gap-4 mb-4">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white">
                {project.title}
              </h1>
              <span className={`px-3 py-1 rounded-lg text-sm border ${
                project.status === 'Live'
                  ? 'bg-green-500/10 text-green-600 dark:text-green-400 border-green-200 dark:border-green-800'
                  : 'bg-yellow-500/10 text-yellow-600 dark:text-yellow-400 border-yellow-200 dark:border-yellow-800'
              }`}>
                {project.status}
              </span>
            </div>
            
            {project.date && (
              <p className="text-gray-500 dark:text-gray-400 text-sm mb-4">
                {project.date}
              </p>
            )}

            <div className="flex flex-wrap gap-2 mb-5">
              {project.technologies.map((tech) => (
                <span
                  key={tech}
                  className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 px-3 py-1 rounded-lg text-sm border border-gray-200 dark:border-gray-700"
                >
                  {tech}
                </span>
              ))}
            </div>
            
            <div className="flex flex-wrap items-center gap-4">
              {project.links.github && (
                <motion.a
                  href={project.links.github}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                >
                  <FaGithub size={18} />
                  <span>GitHub</span>
                </motion.a>
              )}
              {project.links.live && (
                <motion.a
                  href={project.links.live}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                >
                  <FaExternalLinkAlt size={16} />
                  <span>Live Demo</span>
                </motion.a>
              )}
            </div>
          </header>
          
          <div className="p-6 md:p-8">
            <div className="prose dark:prose-invert prose-headings:text-gray-900 dark:prose-headings:text-white prose-a:text-blue-600 dark:prose-a:text-blue-400 max-w-none">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {project.content.split('\n\n').slice(1).join('\n\n')}
              </ReactMarkdown>
            </div>
          </div>
        </motion.article>

        {/* More projects navigation could be added here */}
      </div>
    </div>
  );
}

export default ProjectDetail;
