import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu, FiX, FiSun, FiMoon, FiFileText, FiHome, FiCode, FiBookOpen } from 'react-icons/fi';
import { useTheme } from '../context/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { isDark, toggleTheme } = useTheme();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className="fixed top-0 left-0 right-0 z-50 flex justify-center px-4 pt-5">
      <nav className={`${
        scrolled ? 'shadow-lg' : 'shadow-md'
      } w-full max-w-3xl bg-white dark:bg-gray-800 rounded-xl border-2 border-gray-200 dark:border-gray-700 transition-all duration-300`}>
        <div className="px-4 sm:px-6">
          <div className="flex justify-between items-center h-16">
            {/* Logo/Brand */}
            <Link to="/" className="group flex items-center">
              <motion.span 
                className="text-xl font-bold text-gray-800 dark:text-white"
                whileHover={{ scale: 1.05 }}
              >
                <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                  joneskimk
                </span>
                <span className="text-xs align-top font-medium bg-blue-600 text-white px-1.5 py-0.5 rounded-md ml-1">
                  .space
                </span>
              </motion.span>
            </Link>

            {/* Desktop Navigation with Icons */}
            <div className="hidden md:flex items-center gap-1">
              <NavLink to="/">
                <span className="flex items-center">
                  <FiHome className="mr-1" size={14} />
                  Home
                </span>
              </NavLink>
              <NavLink to="/projects">
                <span className="flex items-center">
                  <FiCode className="mr-1" size={14} />
                  Projects
                </span>
              </NavLink>
              <NavLink to="/blog">
                <span className="flex items-center">
                  <FiBookOpen className="mr-1" size={14} />
                  Brain Dump
                </span>
              </NavLink>
              <NavLink to="/resume">
                <span className="flex items-center">
                  <FiFileText className="mr-1" size={14} />
                  Resume
                </span>
              </NavLink>
              {/* <motion.button
                onClick={toggleTheme}
                className="p-2 ml-2 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 border border-gray-200 dark:border-gray-600"
                aria-label="Toggle theme"
                whileTap={{ scale: 0.95 }}
              >
                {isDark ? <FiSun size={18} /> : <FiMoon size={18} />}
              </motion.button> */}
            </div>

            {/* Mobile Navigation Button */}
            <div className="md:hidden flex items-center gap-2">
              <motion.button
                onClick={toggleTheme}
                className="p-2 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 border border-gray-200 dark:border-gray-600"
                aria-label="Toggle theme"
                whileTap={{ scale: 0.95 }}
              >
                {isDark ? <FiSun size={18} /> : <FiMoon size={18} />}
              </motion.button>
              <motion.button
                onClick={() => setIsOpen(!isOpen)}
                className="p-2 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 border border-gray-200 dark:border-gray-600"
                aria-label="Toggle menu"
                whileTap={{ scale: 0.95 }}
              >
                {isOpen ? <FiX size={18} /> : <FiMenu size={18} />}
              </motion.button>
            </div>
          </div>

          {/* Mobile Navigation Menu with Icons */}
          <AnimatePresence>
            {isOpen && (
              <motion.div
                className="md:hidden pb-4 border-t border-gray-200 dark:border-gray-700 mt-2"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className="flex flex-col space-y-3 py-3">
                  <MobileNavLink to="/" onClick={() => setIsOpen(false)}>
                    <span className="flex items-center">
                      <FiHome className="mr-2" size={18} />
                      Home
                    </span>
                  </MobileNavLink>
                  <MobileNavLink to="/projects" onClick={() => setIsOpen(false)}>
                    <span className="flex items-center">
                      <FiCode className="mr-2" size={18} />
                      Projects
                    </span>
                  </MobileNavLink>
                  <MobileNavLink to="/blog" onClick={() => setIsOpen(false)}>
                    <span className="flex items-center">
                      <FiBookOpen className="mr-2" size={18} />
                      Brain Dump
                    </span>
                  </MobileNavLink>
                  <MobileNavLink to="/resume" onClick={() => setIsOpen(false)}>
                    <span className="flex items-center">
                      <FiFileText className="mr-2" size={18} />
                      Resume
                    </span>
                  </MobileNavLink>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>
    </header>
  );
}

// Helper components for cleaner code
function NavLink({ to, children }) {
  return (
    <Link
      to={to}
      className="px-3 py-2 rounded-lg text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 border border-transparent hover:border-gray-200 dark:hover:border-gray-700 transition-all duration-200"
    >
      {children}
    </Link>
  );
}

function MobileNavLink({ to, onClick, children }) {
  return (
    <motion.div
      initial={{ x: -10, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      <Link
        to={to}
        onClick={onClick}
        className="block px-3 py-2 rounded-lg text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 border border-transparent hover:border-gray-200 dark:hover:border-gray-700"
      >
        {children}
      </Link>
    </motion.div>
  );
}

export default Navbar;
