import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import ProjectList from './components/ProjectList';
import ProjectDetail from './components/ProjectDetail';
import BrainDumpList from './components/BlogList';
import BlogPost from './components/BlogPost';
import Resume from './components/Resume';
import { projects } from './utils/projects';
import { blogPosts } from './utils/blogPosts';

function App() {
  return (
    <ThemeProvider>
      <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
        <Router>
          <Navbar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projects" element={<ProjectList projects={projects} />} />
              <Route path="/projects/:slug" element={<ProjectDetail projects={projects} />} />
              <Route path="/blog" element={<BrainDumpList posts={blogPosts} />} />
              <Route path="/blog/:slug" element={<BlogPost posts={blogPosts} />} />
              <Route path="/resume" element={<Resume />} />
            </Routes>
          </main>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
