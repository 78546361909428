import React from 'react';
import { Link } from 'react-router-dom';
import { FaGraduationCap, FaBriefcase, FaCode, FaTools } from 'react-icons/fa';
import { motion } from 'framer-motion';

function Resume() {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="pt-28 pb-16 max-w-3xl mx-auto px-4">
        {/* Header - Removed Download Button */}
        <motion.div
          className="mb-10"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <h1 className="text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 dark:from-blue-400 dark:to-purple-400 bg-clip-text text-transparent">
            Resume
          </h1>
          <p className="text-gray-600 dark:text-gray-400 mt-2">
            My professional background and qualifications
          </p>
        </motion.div>
        
        {/* Resume Sections */}
        <div className="space-y-10">
          {/* Education Section */}
          <ResumeSection 
            title="Education" 
            icon={<FaGraduationCap size={20} />}
            delay={0.1}
          >
            <div className="space-y-6">
              <ResumeItem
                title="University of Chicago"
                subtitle="M.S. Computer Science | B.S. Physics (Joint BS/MS)"
                date="Expected Graduation: June 2025"
                location="Chicago, IL"
              >
                <p className="text-gray-600 dark:text-gray-400">
                  Relevant Coursework: Algorithms and Data Structures, Data Analysis (Time Series, Computational Astrophysics), 
                  Distributed Systems, Discrete Mathematics, Unix Systems, Web Development, Advanced C++, Advanced Computer Architecture.
                </p>
              </ResumeItem>
            </div>
          </ResumeSection>
          
          {/* Experience Section */}
          <ResumeSection 
            title="Experience" 
            icon={<FaBriefcase size={20} />}
            delay={0.2}
          >
            <div className="space-y-6">
              <ResumeItem
                title="Software Engineering Intern"
                subtitle="Haldune Corp."
                date="Jan 2025 - Present"
                location="Remote"
              >
                <ul className="list-disc list-inside space-y-1 text-gray-600 dark:text-gray-400">
                  <li>Developing a scalable vector database microservice to support a Retrieval-Augmented Generation (RAG) pipeline, optimizing information retrieval</li>
                  <li>Enhancing system architecture to improve scalability, efficiency, and integration with AI-driven data processing</li>
                </ul>
              </ResumeItem>
              
              <ResumeItem
                title="Software Engineering Intern"
                subtitle="GridLink"
                date="Jun 2024 - Aug 2024"
                location="Remote"
              >
                <ul className="list-disc list-inside space-y-1 text-gray-600 dark:text-gray-400">
                  <li>Built a full-stack simulation microservice (NestJS, TypeScript, React) for EV charge management, enabling realistic testing of vehicle behaviors and charging scenarios</li>
                  <li>Integrated Webhooks and REST APIs for real-time data exchange</li>
                  <li>Developed automated tests with Cypress to ensure simulation reliability and backend stability, improving system robustness</li>
                </ul>
              </ResumeItem>
              
              <ResumeItem
                title="Undergraduate Research Assistant"
                subtitle="Vieregg Lab, University of Chicago"
                date="Apr 2023 - Nov 2024"
                location="Chicago, IL"
              >
                <ul className="list-disc list-inside space-y-1 text-gray-600 dark:text-gray-400">
                  <li>Analyzed data from the RNO-G neutrino observatory using C++, Python, and ROOT, focusing on event location reconstruction</li>
                  <li>Identified and classified anthropogenic noise, improving data quality and aiding neutrino signal analysis</li>
                </ul>
              </ResumeItem>
              
              <ResumeItem
                title="Software Engineering Intern"
                subtitle="ClassHook Inc."
                date="Jun 2022 - Sep 2022"
                location="Remote"
              >
                <ul className="list-disc list-inside space-y-1 text-gray-600 dark:text-gray-400">
                  <li>Developed and integrated new features into the ClassHook platform using React and Ruby on Rails, enhancing user experience and functionality</li>
                  <li>Improved code quality and maintainability through refactoring and implemented automated testing with RSpec to ensure platform reliability</li>
                </ul>
              </ResumeItem>
            </div>
          </ResumeSection>
          
          {/* Skills Section */}
          <ResumeSection 
            title="Skills" 
            icon={<FaCode size={20} />}
            delay={0.3}
          >
            <div className="space-y-4">
              <SkillCategory title="Development">
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Python</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">C++</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">C</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">JavaScript/TypeScript</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Ruby</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">React</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Node.js</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Django</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Flask</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">REST APIs</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">SQL</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">PostgreSQL</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">SQLite</span>
              </SkillCategory>
              
              <SkillCategory title="Tools & Methodologies">
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Git</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">GitHub</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Docker</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">CI/CD</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">GitHub Actions</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Unix/Linux</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Shell Scripting</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Agile development</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">Test-driven development</span>
                <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-md text-sm border border-gray-200 dark:border-gray-700 mr-2 mb-2 inline-block">AWS</span>
              </SkillCategory>
            </div>
          </ResumeSection>
          
          {/* Projects Section */}
          <ResumeSection 
            title="Notable Projects" 
            icon={<FaTools size={20} />}
            delay={0.4}
          >
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                  Selected practical projects
                </h3>
                <Link 
                  to="/projects" 
                  className="text-blue-600 dark:text-blue-400 hover:underline text-sm"
                >
                  View all projects →
                </Link>
              </div>
              
              <div className="space-y-4">
                <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
                  <div className="flex justify-between items-start">
                    <h3 className="font-medium text-gray-900 dark:text-white">wazo.space (AI-Powered Zettelkasten)</h3>
                    <span className="text-xs text-gray-500 dark:text-gray-400">Personal Project</span>
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                    Built a full-stack app (React, Express.js, Python) with LLM-driven knowledge graphs, microservices, and in-note code execution.
                  </p>
                </div>
                
                <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
                  <div className="flex justify-between items-start">
                    <h3 className="font-medium text-gray-900 dark:text-white">Oasis LMS (Offline LMS)</h3>
                    <span className="text-xs text-gray-500 dark:text-gray-400">E3Empower Africa — Personal Project</span>
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                    Developed an offline LMS (ReactJS, Express.js) with automated grading and a modular backend for extensibility.
                  </p>
                </div>
              </div>
            </div>
          </ResumeSection>
        </div>
      </div>
    </div>
  );
}

// Helper components
function ResumeSection({ title, icon, children, delay = 0 }) {
  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay }}
    >
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-lg text-blue-600 dark:text-blue-400">
          {icon}
        </div>
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{title}</h2>
      </div>
      <div className="ml-4 border-l-2 border-gray-200 dark:border-gray-700 pl-6">
        {children}
      </div>
    </motion.section>
  );
}

function ResumeItem({ title, subtitle, date, location, children }) {
  return (
    <div>
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-2">
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">{title}</h3>
          <p className="text-blue-600 dark:text-blue-400">{subtitle}</p>
        </div>
        <div className="text-sm text-gray-500 dark:text-gray-400">
          <div className="flex flex-wrap gap-x-2 items-center">
            <span>{date}</span>
            {location && (
              <>
                <span className="hidden md:inline">•</span>
                <span>{location}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="mt-2">
        {children}
      </div>
    </div>
  );
}

function SkillCategory({ title, children }) {
  return (
    <div>
      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-3">{title}</h3>
      <div className="flex flex-wrap">
        {children}
      </div>
    </div>
  );
}

export default Resume;
